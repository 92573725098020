import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';
import logo from '../assets/img/logo.png';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const createWidget = async (logoUrl) => {
  const widgetApiHandler = new ApiStore(WIDGET_API_URL)

  const searchParams = (new URL(document.location)).searchParams;
  const planId = searchParams.get("id");

  const planData = await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    logo: logo,
    tabs: ['panorama', 'rotation'],
    locale: 'talan_8',
    logoUrl,
  }

  new Widget('#widget', options);
}

createWidget('https://xn--80aacf4bwnk3a.xn--80aa6ajv.xn--p1ai/apartments/flats?area_range={%22min%22:%2226.3%22,%22max%22:%2279.1%22}&complex=2322378&cost_filter=sale,+booked&cost_filter=presale&cost_range={%22min%22:%224667400%22,%22max%22:%2210745700%22}&floor_range={%22min%22:%221%22,%22max%22:%2220%22}&group_layout=false&house=1&hypotec_range={%22min%22:%2218625%22,%22max%22:%2242881%2');
